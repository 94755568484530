import React from "react";
import Table from "components/shared/Table";
import { APP_STORES } from "src/utils/market/app-stores";
import * as classes from "../rankings.module.less";

export default () => {
  let columns = [
    {
      hideResponsiveTitle: true,
      title: "App Store",
      dataIndex: "text",
      render: (text, element) => (
        <div className={classes.storeName}>
          <i>{element.index}</i>
          <div className={classes.image}>{element.image}</div>
          {(element.website && element.website != "NOT_AVAILABLE") ? (
            <a
              href={element.website}
              rel="noopener noreferrer"
              target="_blank"
              title={element.website}
            >
              <span>{text}</span>
            </a>
          ) : (
            <span>{text}</span>
          )}
        </div>
      ),
    },
    {
      title: "Market Share",
      dataIndex: "mau_market",
      className: classes.right,
    },
    {
      title: "Monthly Active Users (MAU)",
      dataIndex: "mau",
      className: classes.right,
    },
    // {
    //   title: "MAU Change",
    //   dataIndex: "mau_change",
    //   className: classes.right,
    // },
    {
      title: "Daily Active Users (DAU)",
      dataIndex: "dau",
      className: classes.right,
    },
  ];

  return (
    <Table
      responsive
      dataSource={APP_STORES}
      columns={columns}
      rowKey="index"
      expandedRowRender={(record) => (
        <div>
          {record.details.map((item) => (
            <p className={classes.detailsP}>{item}</p>
          ))}
        </div>
      )}
    />
  );
};
