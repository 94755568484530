import React from "react";
import { StaticImage } from "gatsby-plugin-image";

const imgProps = {
  objectPosition: "50% 50%",
  loading: "lazy",
  layout: "fullWidth",
  placeholder: "blurred",
  alt: "App Store Icon"
};

const RAW_APP_STORES = [{'short_name': 'Huawei App Market',
  'image': <StaticImage src="../../images/stores/Huawei_AppGallery.svg" {...imgProps} />,
  'website': 'https://appgallery.huawei.com',
  'text': 'Huawei App Market',
  'mau_market': '23.84%',
  'mau': '307,609,500',
  'dau': '39,329,400',
  'details': ['Chinese Name: 华为应用市场',
   'Parent Company: Huawei',
   'Company Type: Tech Hardware/Software',
   'Description: Huawei AppGallery is the official application distribution platform for Huawei devices. It serves as an application management platform developed by Huawei for Android, HarmonyOS (Hongmeng OS), and Windows 11 operating systems. It is part of Huawei Mobile Services (HMS) and is pre-installed on Huawei devices in over 170 countries. Huawei AppGallery ensures a safe and secure environment for app downloads and usage through its four-tier detection mechanism and domestically pioneered application rating system. It offers a range of 18 app categories and carefully selects premium digital content from around the world.']},
 {'short_name': 'Tencent Appstore',
  'image': <StaticImage src="../../images/stores/tencent.png" {...imgProps} />,
  'website': 'https://sj.qq.com',
  'text': 'Tencent Appstore',
  'mau_market': '13.55%',
  'mau': '174,876,800',
  'dau': '40,430,000',
  'details': ['Chinese Name: 应用宝',
   'Parent Company: Tencent',
   'Company Type: Online Services',
   'Description: Tencent Appstore, also known as "Tencent MyApp", is a third-party comprehensive management software for Android smartphones developed by Tencent. It is primarily used for downloading applications for the Android platform. With Tencent MyApp, users can freely download software, games, music, videos, e-books, wallpapers, and other resources. It also offers features such as mobile optimization, app detection, one-click root, and data backup.']},
 {'short_name': 'Oppo Software Store',
  'image': <StaticImage src="../../images/stores/oppo.png" {...imgProps} />,
  'website': 'https://store.oppomobile.com',
  'text': 'Oppo Software Store',
  'mau_market': '12.66%',
  'mau': '163,371,900',
  'dau': '43,911,300',
  'details': ['Chinese Name: OPPO软件商店',
   'Parent Company: Oppo',
   'Company Type: Mobile Hardware/Software',
   'Description: OPPO Software Store is the official application and game download and management platform provided by OPPO. It offers users access to over 100,000 resources, including software, games, e-books, fonts, themes, wallpapers, and ringtones, all available for free download. OPPO Software Store ensures the security of its platform by conducting rigorous checks on all resources, protecting every download made by its users.']},
 {'short_name': 'Xiaomi Market',
  'image': <StaticImage src="../../images/stores/miui.png" {...imgProps} />,
  'website': 'https://app.mi.com',
  'text': 'Xiaomi Market',
  'mau_market': '11.68%',
  'mau': '150,679,200',
  'dau': '51,161,100',
  'details': ['Chinese Name: 小米应用商店',
   'Parent Company: Xiaomi',
   'Company Type: Tech Hardware/Software',
   'Description: Xiaomi Market is an Android application recommendation software that is included in the MIUI ROM, developed by Xiaomi Technology. The purpose of Xiaomi Market is to discover the most enjoyable Android applications and games.']},
 {'short_name': 'VIVO App Store',
  'image': <StaticImage src="../../images/stores/vivo.png" {...imgProps} />,
  'website': 'https://www.vivo.com.cn',
  'text': 'VIVO App Store',
  'mau_market': '10.65%',
  'mau': '137,388,800',
  'dau': '24,956,400',
  'details': ['Chinese Name: vivo 应用商店',
   'Parent Company: Vivo',
   'Company Type: Mobile Hardware/Software',
   'Description: Vivo App Store is the official application and game distribution platform of Vivo smartphones. It has a large user base and robust distribution capabilities. The platform aims to create a healthy growth environment for applications and provide developers with a high-quality service platform.']},
 {'short_name': 'Honor Store',
  'image': <StaticImage src="../../images/stores/honor_logo.png" alt="logo" {...imgProps} />,
  'website': 'NOT_AVAILABLE',
  'text': 'Honor Store',
  'mau_market': '6.95%',
  'mau': '89,657,100',
  'dau': '29,077,500',
  'details': ['Chinese Name: HONOR',
   'Parent Company: Shenzhen Zhixinxin Information Technology Ltd',
   'Company Type: Mobile Hardware/Software',
   'Description: originally a brand under Huawei, was established in 2013 and is a global leader in providing smart devices. The Honor App Store is the official application distribution platform for Honor devices, offering a vast array of high-quality resources and smooth downloads.']},
 {'short_name': 'Samsung App Store',
  'image': <StaticImage src="../../images/stores/samsung_app_store.png" {...imgProps} />,
  'website': 'https://galaxystore.samsung.com',
  'text': 'Samsung App Store',
  'mau_market': '5.11%',
  'mau': '65,952,800',
  'dau': '5,754,500',
  'details': ['Chinese Name: 三星应用商店',
   'Parent Company: Samsung Group',
   'Company Type: Multinational Conglomerate',
   'Description: Samsung Electronics is a South Korean multinational manufacturer of smartphones, TVs, semiconductors, and other home electronics. Its flagship series of smartphones is the Galaxy series, and the Samsung App Store comes preinstalled on all Samsung smartphones. In 2019, Samsung announced it would stop manufacturing mobile phones in China due to lack of demand.']},
 {'short_name': '360 Mobile Assistant',
  'image': <StaticImage src="../../images/stores/360.png" {...imgProps} />,
  'website': 'http://app.haosou.com',
  'text': '360 Mobile Assistant',
  'mau_market': '4.10%',
  'mau': '52,879,400',
  'dau': '9,811,400',
  'details': ['Chinese Name: 360手机助手',
   'Parent Company: Qihoo 360',
   'Company Type: Internet Security/Software',
   'Description: 360 Mobile Assistant is a Chinese app store from Qihoo 360, a Chinese internet security company with products including antivirus software and a popular web browser used by millions across China.']},
 {'short_name': 'Baidu Mobile Assistant',
  'image': <StaticImage src="../../images/stores/baidu.png" {...imgProps} />,
  'website': 'NOT_AVAILABLE',
  'text': 'Baidu Mobile Assistant',
  'mau_market': '3.59%',
  'mau': '46,309,500',
  'dau': '3,755,900',
  'details': ['Chinese Name: 百度手机助手',
   'Parent company: Baidu',
   'Company Type: Internet Search Engine/Software',
   'Description: Even before Google left the Chinese market in 2010, Baidu had been the country’s largest search engine. It remains the dominant player to this day, which is why it is often referred to as “The Google of China”. Baidu’s app store, Baidu Mobile Assistant, is always served up as an option when users search for an app on baidu.com.']},
 {'short_name': 'Wandoujia',
  'image': <StaticImage src="../../images/stores/wandoujia.png" {...imgProps} />,
  'website': 'https://www.wandoujia.com',
  'text': 'Wandoujia',
  'mau_market': '0.97%',
  'mau': '12,533,100',
  'dau': '1,301,400',
  'details': ['Chinese Name: 豌豆荚',
   'Parent Company: Alibaba',
   'Company Type: Online Services',
   'Description: Wandoujia was founded in 2009 by a former employee of Google China and a telecoms engineer. It was China’s 5th largest Android app store when it was purchased by Alibaba in 2016. The company developed a multimedia marketplace as an alternative to streaming large amounts of data that allows users to search for videos across multiple platforms, download them locally, and compress them.']},
 {'short_name': 'PP Assistant',
  'image': <StaticImage src="../../images/stores/pplogo.png" {...imgProps} />,
  'website': 'http://www.pp.cn',
  'text': 'PP Assistant',
  'mau_market': '0.64%',
  'mau': '8,313,000',
  'dau': '870,500',
  'details': ['Chinese Name: PP助手',
   'Parent Company: Alibaba',
   'Company Type: Online Services',
   "Description: PP Assistant is Alibaba's official app store in China. In addition to apps for Android, PP Assistant also has two app stores for iOS, including one for iOS devices that have been “jailbroken”."]},
 {'short_name': 'ZTE App Store',
  'image': <StaticImage src="../../images/stores/zte.jpeg" {...imgProps} />,
  'website': 'https://www.ztems.com',
  'text': 'ZTE App Store',
  'mau_market': '0.54%',
  'mau': '6,942,100',
  'dau': '303,900',
  'details': ['Chinese name: 中兴应用商店',
   'Parent company: ZTE',
   'Company Type: Telecom Equipment and Systems',
   'Description: ZTE is was founded in 1985 in Shenzhen, China, and is one of China’s leading telecom equipment manufacturers. ZTE now has a strong international presence, with subsidiaries around the world. They make mobile phones, smartphones, tablet computers, and OEM hardware and software. It is one of the top five mobile phone manufacturers in China. The ZTE app store comes preinstalled on its smartphones in China.']},
 {'short_name': 'Coolpad',
  'image': <StaticImage src="../../images/stores/coolpad.png" {...imgProps} />,
  'website': 'https://www.coolpad.com (App store is not avaliable in Webpage)',
  'text': 'Coolpad',
  'mau_market': '0.48%',
  'mau': '6,253,300',
  'dau': '667,700',
  'details': ['Chinese Name: 酷派应用商店',
   'Parent Company: Coolpad',
   'Company Type: Mobile Hardware/Software',
   'Description: CoolPad Group was founded in 1993 and previously known as China Wireless Technologies Limited. At first a maker of pagers and paging systems, it started to make mobile phones in 2002. The company is headquartered in Shenzhen. Its market penetration in China has diminished substantially in recent years as it has faced heavy losses and a series of ownership changes. The CoolPad app store comes preinstalled on its devices.']},
 {'short_name': 'Lenovo Le Store',
  'image': <StaticImage src="../../images/stores/lenovo.png" {...imgProps} />,
  'website': 'https://lestore.lenovo.com',
  'text': 'Lenovo Le Store',
  'mau_market': '0.44%',
  'mau': '5,652,500',
  'dau': '356,200',
  'details': ['Chinese Name: 联想乐商店',
   'Parent Company: Lenovo',
   'Company Type: Mobile Hardware/Software',
   'Description: Lenovo is a Chinese multinational company, founded in 1984 and headquartered in Beijing. By 2019, it was the world’s largest PC vendor by unit sales, but they also develop storage devices, IT management software, smartphones, tablets and smart TVs running the Android operating system. The Lenovo Le Store is preinstalled on many of its devices, including Smart TVs.']},
 {'short_name': 'Anzhi Market',
  'image': <StaticImage src="../../images/stores/anzhi.png" {...imgProps} />,
  'website': 'NOT_AVAILABLE',
  'text': 'Anzhi Market',
  'mau_market': '0.21%',
  'mau': '2,720,100',
  'dau': '129,700',
  'details': ['Chinese Name: 安智市场',
   'Parent Company: Anzhi',
   'Company Type: Software',
   'Description: Anzhi Market is a China app store that has been around for many years. It has an active community that allows members to discuss and rate Android apps. Anzhi Market was launched in June 2010.']},
 {'short_name': 'AppChina',
  'image': <StaticImage src="../../images/stores/appchina.png" {...imgProps} />,
  'website': 'http://m.appchina.com',
  'text': 'AppChina',
  'mau_market': '0.13%',
  'mau': '1,708,300',
  'dau': '120,300',
  'details': ['Chinese name: 应用汇',
   'Parent company: AppChina',
   'Company Type: Technology / Application Services',
   'Description: AppChina - not to be confused with our company, AppInChina - is an independent, standalone app store, not connected with any hardware company or products. Within 9 months after it launched in 2011, it had more than 100 million downloads. It was one of the early projects of the Innovation Workshop investment group, hosting apps and games through its app store app and website.']},
 {'short_name': 'Meizu Flyme',
  'image': <StaticImage src="../../images/stores/meizu.png" {...imgProps} />,
  'website': 'https://app.meizu.com',
  'text': 'Meizu Flyme',
  'mau_market': '0.13%',
  'mau': '1,680,400',
  'dau': '99,600',
  'details': ['Chinese Name: 魅族应用商店',
   'Parent Company: Meizu',
   'Company Type: Telecoms Equipment',
   'Description: Established in 2003 and headquartered in Zhuhai, China, Meizu designs and produces smartphones created to provide a simple, intuitive mobile experience for people whose time is expected to be simply spent in using their devices instead of figuring out the way of using them. Meizu expanded into the smartphone market in 2008 and has been committed to developing high-end smartphones ever since.']},
 {'short_name': 'LeTV Store',
  'image': <StaticImage src="../../images/stores/letv_store.png" {...imgProps} />,
  'website': 'https://mobile.le.com',
  'text': 'LeTV Store',
  'mau_market': '0.09%',
  'mau': '1,211,700',
  'dau': '80,800',
  'details': ['Chinese Name: 乐视应用商店',
   'Parent Company: LeTV Interactive Entertainment Technology',
   'Company Type: Tech/Entertainment',
   'Description: Founded in 2016, LeTV Interactive Entertainment Technology Co., Ltd provides mobile devices and smart TVs with apps and games. LeTV integrated game development, publishing, operating, distribution, and smart accessories into its multi-functional interactive entertainment platform with its seven sub-ecologies.']},
 {'short_name': 'Google Play',
  'image': <StaticImage src="../../images/stores/googleplay.png" {...imgProps} />,
  'website': 'https://play.google.com',
  'text': 'Google Play',
  'mau_market': '0.09%',
  'mau': '1,209,000',
  'dau': '72,300',
  'details': ['Chinese name: 谷歌应用商店',
   'Parent company: Google',
   'Company Type: Internet Search Engine/Software',
   'Description: Google Play is the official flagship Android app store from Google, originally launched alongside its Android operating system in October 2008 as Android Market. It has since grown to host apps, games, music, and eBooks. Because of government restrictions, Google Play is not available in China, which has led to the creation of an ecosystem of third-party Android app stores in Mainland China. It is only accessible by Chinese users using a VPN.']},
 {'short_name': 'Coolapk',
  'image': <StaticImage src="../../images/stores/coolmarket.png" {...imgProps} />,
  'website': 'https://www.coolapk.com',
  'text': 'Coolapk',
  'mau_market': '0.09%',
  'mau': '1,103,400',
  'dau': '153,200',
  'details': ['Chinese Name: 酷安, also known as 酷市场',
   'Parent Company: Coolapk',
   'Company Type: App Store',
   "Description: Coolapk is an independent app market based in Shenzhen, formerly known as Cool Market. The company also has a WeChat public account called Ku'an Digital, a WeChat applet, and a channel on bilibili.com where they host reviews of tech products and software. The company’s slogan is “Discover New Life in Science and Technology”."]},
 {'short_name': 'Sogou Mobile Assistant',
  'image': <StaticImage src="../../images/stores/sogou.png" {...imgProps} />,
  'website': 'NOT AVAILABLE',
  'text': 'Sogou Mobile Assistant',
  'mau_market': '0.07%',
  'mau': '910,100',
  'dau': '85,100',
  'details': ['Chinese Name: 搜狗手机助手',
   'Parent Company: Sogou',
   'Company Type: Internet Search Engine/Software',
   'Description: Sogou is originally a search engine and smartwatch company, launched in 2004. In 2006, the company invented Sogou Pinyin, which is now the dominant input platform in China for both desktop and mobile systems. They are currently focused on developing AI, lip reading, and voice recognition technology for its smartwatches and translation devices. Sogou is a Beijing-based public company, listed on the New York Stock Exchange. They are currently majority-owned by Tencent, and it is the only search engine able to search WeChat public accounts.']},
 {'short_name': 'Zhuoyi',
  'image': <StaticImage src="../../images/stores/zhuoyi.png" {...imgProps} />,
  'website': 'https://market.droi.com',
  'text': 'Zhuoyi',
  'mau_market': '0.06%',
  'mau': '801,800',
  'dau': '223,200',
  'details': ['Chinese Name: 卓易市场',
   'Parent Company: Shanghai Zhuoyou Network Technology',
   'Company Type: Mobile Hardware/Software',
   'Description: Zhuoyi is an independent app store developed and maintained by a Shanghai-based company established in April 2013 and engaged in a variety of business activities, including technology and business consulting, ecommerce, event and conference planning and support, and software development. In addition to the Zhuoyi app store, they also develop and maintain the Android-based Freeme OS.']},
 {'short_name': 'Gionee',
  'image': <StaticImage src="../../images/stores/gionee.png" {...imgProps} />,
  'website': 'http://www.gionee.com/shzb',
  'text': 'Gionee',
  'mau_market': '0.06%',
  'mau': '752,600',
  'dau': '45,400',
  'details': ['Chinese Name: 金立软件商店, also known as 易用汇',
   'Parent Company: Gionee Communication Equipment',
   'Company Type: Electronics',
   'Description: Launched in 2011, Gionee app store was developed and dedicated to android users.']},
 {'short_name': 'China Mobile MM Store',
  'image': <StaticImage src="../../images/stores/mm.png" {...imgProps} />,
  'website': 'https://mm.10086.cn (App store is not avaliable in Webpage)',
  'text': 'China Mobile MM Store',
  'mau_market': '0.05%',
  'mau': '625,800',
  'dau': '29,200',
  'details': ['Chinese Name: MM 应用商场',
   'Parent Company: China Mobile',
   'Company Type: Telecommunications Operator',
   'Special Requirements: Requires SDK integration and takes a 30% share of revenue',
   'Description: China Mobile is one of three state-owned telecoms companies in China that provides mobile services across Mainland China.']},
 {'short_name': 'HiMarket',
  'image': <StaticImage src="../../images/stores/himarket.png" {...imgProps} />,
  'website': 'http://apk.hiapk.com',
  'text': 'HiMarket',
  'mau_market': '0.05%',
  'mau': '627,700',
  'dau': '37,900',
  'details': ['Chinese Name: 安卓市场',
   'Parent Company: Baidu',
   'Company Type: Internet Search Engine/Software',
   'Description: HiMarket was founded in Sept 2009 by 91 Wireless (also the creator of 91 Wireless Assistant), which was later bought out by Baidu in 2013 for $1.85 billion USD. The HiMarket app store has both an English and Chinese version of its app store.']}]

export const APP_STORES = RAW_APP_STORES.map((it, index) => ({ ...it, index: index + 1 }));
